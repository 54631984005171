<template>
  <div>
    <v-container :class="{ 'px-0': $vuetify.breakpoint.smAndDown }">
      <v-card class="msa-list mt-3">
        <v-row v-if="$vuetify.breakpoint.mdAndUp">
          <v-col class="pt-0 pb-0">
            <v-text-field
              id="access-requests--search-field"
              v-model="searchString"
              aria-label="filter access requests"
              class="data-table--search-field"
              hide-details
              label=""
              outlined
              placeholder="Search access requests"
              prepend-inner-icon="search"
              @input="onSearchChangeDebounced"
            />
          </v-col>
        </v-row>
        <v-row v-if="$vuetify.breakpoint.mdAndUp" class="data-table--header">
          <v-col>
            <v-list class="pa-0">
              <v-list-item>
                <div class="mr-4" style="width:42px" />
                <v-list-item-content>
                  <v-row class="ml-0">
                    <v-col class="col-sm-3">
                      <v-list-item-title>
                        <span>Name</span>
                      </v-list-item-title>
                    </v-col>
                    <v-col class="col-sm-2">
                      <v-list-item-title>
                        <span>Date requested</span>
                      </v-list-item-title>
                    </v-col>
                    <v-col class="col-sm-7">
                      <v-list-item-title>
                        <span>App requested</span>
                      </v-list-item-title>
                    </v-col>
                  </v-row>
                </v-list-item-content>
                <v-list-item-action class="mr-6" />
                <v-list-item-action class="mr-1" />
                <v-list-item-action class="mr-3" />
              </v-list-item>
            </v-list>
          </v-col>
        </v-row>

        <div v-if="!allPendingRequests.length">
          <div class="pa-12 body-1">
            <span v-if="searchString"
              >No pending requests found for <b>{{ searchString }}</b
              >.</span
            >
            <span v-else>No pending requests found.</span>
          </div>
        </div>
        <div v-if="allPendingRequests.length">
          <v-list v-if="$vuetify.breakpoint.mdAndUp">
            <v-list-item
              v-for="(item, idx) in allPendingRequests"
              :key="idx"
              @click="openDetails(item)"
            >
              <InitialsIcon
                :given-name="item.requestorFirstName"
                :surname="item.requestorLastName"
                class="ml-3 mr-2"
              />
              <v-list-item-content>
                <v-row class="ml-0">
                  <v-col class="col-sm-3">
                    <div class="mb-1">
                      <span>{{
                        (item.requestorFirstName +
                          " " +
                          item.requestorLastName)
                          | capitalise
                      }}</span>
                    </div>
                    <div class="">
                      <span class="ads-dark-60">{{
                        determineStaffRole(item.staff)
                      }}</span>
                    </div>
                  </v-col>
                  <v-col class="col-sm-2">
                    <div class="mb-1">
                      {{ item.requestedTimestamp | time }}
                    </div>
                    <div>
                      {{ item.requestedTimestamp | date }}
                    </div>
                  </v-col>
                  <v-col class="col-sm-7 d-flex align-center">
                    <AppIcon
                      v-if="isApp(item)"
                      :app-name="item.application.icon"
                    />
                    <AppIconRole
                      v-if="!isApp(item)"
                      :role-code="item.roleCode"
                    />
                    <span class="pl-3" style="line-height: 1.5rem">{{
                      item.requestDescription
                    }}</span>
                    <RequestStatusPill
                      v-if="item.approvalStatus.toLowerCase() === 'e'"
                      :approval-status="item.approvalStatus"
                      class="ml-2"
                    />
                  </v-col>
                </v-row>
              </v-list-item-content>

              <!-- Actions -->
              <v-list-item-action class="mr-6">
                <v-btn
                  v-if="fnActionByName(uIActions.APPROVE_APPLICATION)"
                  icon
                  @click.stop="approve(item.approvalId, true)"
                >
                  <v-icon color="#00A908">
                    mdi-check
                  </v-icon>
                </v-btn>
              </v-list-item-action>
              <v-list-item-action class="mr-1">
                <v-btn
                  v-if="fnActionByName(uIActions.APPROVE_APPLICATION)"
                  icon
                  @click.stop="approve(item.approvalId, false)"
                >
                  <v-icon color="#CE0037">
                    mdi-close
                  </v-icon>
                </v-btn>
              </v-list-item-action>
              <v-list-item-action class="mr-3">
                <v-btn icon>
                  <v-icon>mdi-chevron-right</v-icon>
                </v-btn>
              </v-list-item-action>
            </v-list-item>
          </v-list>

          <!-- mobile flex view -->
          <div v-if="$vuetify.breakpoint.smAndDown">
            <div
              v-for="(item, idx) in allPendingRequests"
              :key="idx"
              class="d-flex align-center py-3 mobile-list-item"
              style="min-width: 100%"
              @click="openDetails(item)"
            >
              <StaffRequestHeaderMobile :request="item" class="pl-3">
                <template v-slot:after>
                  <v-btn icon>
                    <v-icon>
                      mdi-plus
                    </v-icon>
                  </v-btn>
                </template>
              </StaffRequestHeaderMobile>
            </div>
          </div>
        </div>
      </v-card>

      <v-dialog
        v-if="showDetailsModal"
        v-model="showDetailsModal"
        :fullscreen="$vuetify.breakpoint.smAndDown"
        max-width="650px"
      >
        <AccessReviewModal
          :request="selectedRequest"
          @close="showDetailsModal = false"
        />
      </v-dialog>
    </v-container>
  </div>
</template>

<script>
import { AppIcon, InitialsIcon } from "@nswdoe/doe-ui-core";
import AppIconRole from "@/components/AppIconRole";
import StaffRequestHeaderMobile from "@/components/StaffRequestHeaderMobile";
import AccessReviewModal from "@/components/AccessReviewModal";
import RequestStatusPill from "@/components/RequestStatusPill";
import { mapGetters } from "vuex";
import searchDebounce from "@/mixins/searchDebounce";
import { UI_ACTIONS } from "@/utils/constants";

export default {
  components: {
    InitialsIcon,
    AppIcon,
    AppIconRole,
    StaffRequestHeaderMobile,
    AccessReviewModal,
    RequestStatusPill
  },
  data() {
    return {
      showDetailsModal: false,
      selectedRequest: null,
      searchString: this.$store.state.pendingRequestsFilter,
      showComment: false,
      comment: "",
      uIActions: UI_ACTIONS
    };
  },
  computed: {
    ...mapGetters({
      isLoading: "isLoading",
      locationId: "selectedSchoolId",
      locationName: "selectedSchoolName",
      allPendingRequestsFiltered: "allPendingRequestsFiltered",
      staffRole: "staffRole",
      fnActionByName: "getActionByName"
    }),
    allPendingRequests() {
      return this.allPendingRequestsFiltered(this.locationId) ?? [];
    }
  },
  watch: {
    locationId: function(newSchool, oldSchool) {
      if (newSchool != null && newSchool !== oldSchool) {
        this.$store.dispatch("fetchAllPendingRequests", newSchool);
      }
    }
  },
  created() {
    this.$store.commit("setPendingRequestsFilter", this.searchString);
    if (this.locationId != null) {
      this.$store.dispatch("fetchAllPendingRequests", this.locationId);
    }
  },
  beforeDestroy() {
    this.$store.commit("setPendingRequestsFilter", "");
  },
  mixins: [searchDebounce],
  methods: {
    determineStaffRole(staffNetRegId) {
      return this.staffRole(this.locationId, staffNetRegId);
    },
    isApp(item) {
      return item && item.application != null;
    },
    openDetails(item) {
      this.selectedRequest = item;
      this.showDetailsModal = true;
    },
    approve(approvalId, approve) {
      const data = {
        locationId: this.locationId,
        requestId: approvalId,
        comment: "",
        approve,
        approverId: this.$OAuth.data.profile.short_name,
        approverDetUserId: this.$OAuth.data.profile.uid
      };

      this.$store.dispatch("actionRequest", data);
    }
  }
};
</script>

<style lang="scss" scoped>
#app:not(.mobile) .v-expansion-panel-content {
  background: $ads-light-10;
}

.v-expansion-panel-header {
  padding: 0;
  padding-right: 12px;
}

.flex-fluid-clipped {
  flex-grow: 2;
  flex-shrink: 1;
  overflow: hidden;

  div {
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
  }
}

.mobile-list-item {
  border-bottom: 1px solid $ads-light-20;
}
</style>
