<template>
  <div
    class="d-flex align-center"
    style="min-width: 100%"
  >
    <slot name="before" />
    <InitialsIcon
      :given-name="request.requestorFirstName"
      :surname="request.staff.requestorLastName"
      class="mr-2"
    />
    <div class="flex-fluid-clipped ml-2">
      <div>
        {{ request.requestorFirstName + ' ' + request.requestorLastName | capitalise }} - <span class="ads-dark-60">{{ determineStaffRole(request.staff) }}</span>
      </div>
      <div class="ads-grey-dark">
        {{ request.requestDescription }}
      </div>
      <div v-if="request.approvalStatus.toLowerCase() === 'e'">
        <RequestStatusPill :approval-status="request.approvalStatus" />
      </div>
    </div>

    <v-spacer />

    <div class="ml-2">
      <AppIcon
        v-if="isApp"
        :app-name="request.application.icon"
      />
      <AppIconRole
        v-if="!isApp"
        :role-code="request.roleCode"
      />
    </div>
    <slot name="after" />
  </div>
</template>

<script>
import { mapGetters } from 'vuex';
import { AppIcon, InitialsIcon } from "@nswdoe/doe-ui-core";
import AppIconRole from '@/components/AppIconRole';
import RequestStatusPill from '@/components/RequestStatusPill';

export default {
  name: "StaffRequestHeaderMobile",
  components: {
    AppIcon,
    InitialsIcon,
    AppIconRole,
    RequestStatusPill
  },
  props: {
    request: {
      type: Object,
      required: true
    }
  },
  computed: {
    ...mapGetters({
      locationId: 'selectedSchoolId',
      staffRole: 'staffRole'
    }),
    isApp() {
      return this.request && this.request.application != null;
    }
  },
  methods: {
    determineStaffRole(staffNetRegId) {
      return this.staffRole(this.locationId, staffNetRegId);
    }
  }
}
</script>

<style lang="scss" scoped>
  .flex-fluid-clipped {
    flex-grow: 2;
    flex-shrink: 1;
    overflow: hidden;
    div {
      white-space: nowrap;
      overflow: hidden;
      text-overflow: ellipsis;
    }
  }
</style>
