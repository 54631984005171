<template>
  <v-card
    v-if="this.request"
    id="access-review-modal--card"
    v-ads-set-focus:access-review-modal--approve-button
    v-ads-trap-focus
    :flat="$vuetify.breakpoint.smAndDown"
    :tile="$vuetify.breakpoint.smAndDown"
    aria-labelledby="access-review-modal--heading"
    role="dialog"
  >
    <div class="px-6 py-3 py-md-6">
      <!-- DESKTOP -->
      <div v-if="$vuetify.breakpoint.mdAndUp">
        <div class="d-flex flex-column mb-4">
          <div class="d-flex align-center ml-5">
            <div class="mr-4">
              <CircleIcon>
                <template slot="icon">
                  <!-- embedded for IE11's issues icon='rate_review' -->
                  <svg
                    fill="none"
                    height="20"
                    viewBox="0 0 20 20"
                    width="20"
                    xmlns="http://www.w3.org/2000/svg"
                  >
                    <path
                      clip-rule="evenodd"
                      d="M2 0H18C19.1 0 20 0.9 20 2V14C20 15.1 19.1 16 18 16H4L0 20L0.01 2C0.01 0.9 0.9 0 2 0ZM3.17 14H18V2H2V15.17L2.58 14.59L3.17 14ZM8.5 12H16V10H10.5L8.5 12ZM12.36 5.42C12.56 5.62 12.56 5.93 12.36 6.13L6.47 12H4V9.53L9.88 3.65C10.08 3.45 10.39 3.45 10.59 3.65L12.36 5.42Z"
                      fill="#FFFFFF"
                      fill-rule="evenodd"
                    />
                  </svg>
                </template>
              </CircleIcon>
            </div>
            <h3 id="access-review-modal--heading">
              Review access request
            </h3>
            <div class="d-flex" style="flex: 1; justify-content: flex-end">
              <v-btn aria-label="close" icon left @click="$emit('close')">
                <v-icon>mdi-close</v-icon>
              </v-btn>
            </div>
          </div>
        </div>
        <v-card class="px-6 py-3">
          <div class="d-flex align-center">
            <AppIcon v-if="isApp" :app-name="appIcon" />
            <AppIconRole v-if="!isApp" :role-code="request.roleCode" />
            <div class="ml-4 d-flex">
              <span>{{ appDesc }}</span>
              <RequestStatusPill
                v-if="request.approvalStatus.toLowerCase() === 'e'"
                :approval-status="request.approvalStatus"
                class="ml-2"
              />
            </div>
          </div>
        </v-card>

        <div class="d-flex align-start mt-6">
          <InitialsIcon
            :given-name="request.requestorFirstName"
            :surname="request.requestorLastName"
            class="ml-6 mr-4"
          />
          <div>
            <div>
              <span class="subtitle-1">{{
                (request.requestorFirstName + " " + request.requestorLastName)
                  | capitalise
              }}</span>
            </div>
            <div>
              <span class="subtext">{{
                determineStaffRole(request.staff)
              }}</span>
            </div>
            <div>
              <span class="subtext">{{ request.requesterDetUserId }}</span>
            </div>
            <div :class="{ 'ie-wrap-fix': isIE }" class="pt-2">
              <span class="wrappable-comment">{{
                request.requestorComments
              }}</span>
            </div>
          </div>
        </div>

        <div class="pt-6 ml-6">
          <v-row>
            <v-col cols="8">
              <ReadOnlyField
                :value="request.accessRequested"
                label="Access requested"
              />
            </v-col>
            <v-col>
              <ReadOnlyField
                :value="request.requestedTimestamp"
                filter="timeDate"
                label="Date requested"
              />
            </v-col>
          </v-row>
        </div>
      </div>

      <!-- MOBILE -->
      <div v-if="$vuetify.breakpoint.smAndDown">
        <div class="mobile-modal-app-header">
          <v-btn
            aria-label="back"
            class="mr-1"
            color="#FFFFFF"
            icon
            left
            @click="$emit('close')"
          >
            <v-icon>mdi-arrow-left</v-icon>
          </v-btn>
          <div>
            <span class="subtitle-1">Review Access Request</span>
          </div>
        </div>
        <div class="mobile-header-shim" />
        <StaffRequestHeaderMobile :request="request" class="mt-3" />
        <v-row class="mt-3">
          <v-col cols="6">
            <ReadOnlyField
              :value="request.requesterDetUserId"
              label="User ID"
            />
          </v-col>
          <v-col cols="6">
            <ReadOnlyField
              :value="request.requestedTimestamp"
              filter="date"
              label="Date requested"
            />
          </v-col>
        </v-row>

        <div class="mt-3">
          <ReadOnlyField
            :value="request.accessRequested"
            label="Access requested"
          />
        </div>

        <div class="mt-6">
          <ReadOnlyField :value="request.requestorComments" label="Comment" />
        </div>
      </div>

      <div class="pt-6">
        <v-form ref="form" v-model="isValid">
          <v-textarea
            id="access-review-modal--reason-input"
            v-model="comment"
            :rules="[rules.maxLength]"
            counter="255"
            maxlength="255"
            name="access-request-comment"
            outlined
            placeholder=" "
            rows="3"
          >
            <label slot="label" style="font-size: 1.3rem; line-height: 1.2rem">
              Denial of access reason (Optional)
            </label>
          </v-textarea>
        </v-form>
      </div>
      <div
        :class="{
          'justify-end': $vuetify.breakpoint.mdAndUp,
          'justify-space-between': $vuetify.breakpoint.smAndDown
        }"
        class="d-flex mt-3"
      >
        <v-btn
          v-if="fnActionByName(uIActions.APPROVE_APPLICATION)"
          id="access-review-modal--deny-button"
          :disabled="actionInProgress || !isValid"
          :height="$vuetify.breakpoint.smAndDown ? '32px' : '48px'"
          :width="$vuetify.breakpoint.smAndDown ? '120px' : 'auto'"
          class="mr-6"
          outlined
          @click="approve(false)"
        >
          <span>Deny</span>
        </v-btn>
        <v-btn
          v-if="fnActionByName(uIActions.APPROVE_APPLICATION)"
          id="access-review-modal--approve-button"
          :disabled="actionInProgress || !isValid"
          :height="$vuetify.breakpoint.smAndDown ? '32px' : '48px'"
          class="primary"
          width="120px"
          @click="approve(true)"
        >
          <span>Approve</span>
        </v-btn>
      </div>
    </div>
  </v-card>
</template>

<script>
import CircleIcon from "@/components/CircleIcon";
import StaffRequestHeaderMobile from "@/components/StaffRequestHeaderMobile";
import ReadOnlyField from "@/components/ReadOnlyField";
import { AppIcon, InitialsIcon } from "@nswdoe/doe-ui-core";
import AppIconRole from "@/components/AppIconRole";
import RequestStatusPill from "@/components/RequestStatusPill";

import { mapGetters } from "vuex";
import { UI_ACTIONS } from "@/utils/constants";

export default {
  name: "AppTaskList",
  props: {
    request: {
      type: Object,
      required: true
    }
  },
  components: {
    CircleIcon,
    StaffRequestHeaderMobile,
    ReadOnlyField,
    AppIcon,
    InitialsIcon,
    AppIconRole,
    RequestStatusPill
  },
  computed: {
    ...mapGetters({
      locationId: "selectedSchoolId",
      staffRole: "staffRole",
      fnActionByName: "getActionByName"
    }),
    isApp() {
      return this.request && this.request.application != null;
    }
  },
  data() {
    return {
      isValid: true,
      approvalId: this.request.approvalId,
      appIcon: this.request.application ? this.request.application.icon : null,
      appDesc: this.isApp
        ? this.request.application.applicationDesc
        : this.request.requestDescription,
      comment: "",
      actionInProgress: false,
      isIE: document.documentMode != null,
      rules: {
        maxLength: v => v.length <= 255 || "Max length 255 characters"
      },
      uIActions: UI_ACTIONS
    };
  },
  methods: {
    determineStaffRole(staffNetRegId) {
      return this.staffRole(this.locationId, staffNetRegId);
    },
    approve(approve) {
      if (this.$refs.form.validate()) {
        this.actionInProgress = true;
        const data = {
          locationId: this.locationId,
          requestId: this.approvalId,
          comment: this.comment,
          approve,
          approverId: this.$OAuth.data.profile.short_name,
          approverDetUserId: this.$OAuth.data.profile.uid
        };
        this.$store
          .dispatch("actionRequest", data)
          .then(() => {
            this.$emit("close");
          })
          .catch(() => {
            this.actionInProgress = false;
          });
      }
    }
  }
};
</script>

<style lang="scss" scoped>
.subtext {
  color: $ads-dark-70;
}

.theme--dark {
  .subtext {
    color: $ads-light-20;
  }
}

.mobile-modal-app-header {
  display: flex;
  flex: 1 1 auto;
  align-items: center;
  background: $ads-navy;
  color: $ads-white !important;
  position: fixed;
  top: 0;
  left: 0;
  width: 100vw;
  height: 56px;
  z-index: 6;
}

.mobile-header-shim {
  height: 56px;
  margin: 0;
  padding: 0;
}

.ie-wrap-fix {
  max-width: 445px;
  word-wrap: break-word;
}

.wrappable-comment {
  overflow-wrap: anywhere;
}
</style>
